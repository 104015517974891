<template>
  <div class="UserHome">
    <h1> This is User Home page   </h1>
  </div>
</template>
<script>
  export default{
    name: 'UserHomePage,'
  };
</script>
